import { useEffect, useState } from "react";

 const useDeviceType = () => {
    const [ width, setWidth ] = useState<number>(screen.width)

    useEffect(() => {
        const handleResize = () => {
            setWidth(screen.width);
          };
      
          window.addEventListener('resize', handleResize);

          return () => {
            window.removeEventListener('resize', handleResize);
          };
      
      
    }, [])

    const getDeviceType = () => {
        const width = screen.width;
    
        if (width < 740) {
            return "mobile";
        }
    
        if (width > 900) {
            return "desktop";
        }
    
        return "tablet";
    }

    return getDeviceType()
}
  
export default useDeviceType;

const API_ENDPOINT = 'https://dillo-backend-xxiqu27jia-tl.a.run.app';

export { API_ENDPOINT };

const FRAMES_LENGTH_FOR_TRAINING = 20

export {FRAMES_LENGTH_FOR_TRAINING}

const FRAMES_LENGTH_FOR_PREDICT = 20

export {FRAMES_LENGTH_FOR_PREDICT}

const FRAMES_LENGTH_FOR_PREDICT_ALPHABET = 5

export {FRAMES_LENGTH_FOR_PREDICT_ALPHABET}

const MODAL_PROBABILITY_THRESHOLD = 80;

export {MODAL_PROBABILITY_THRESHOLD}

export const maxVideoWidth = 960;
export const maxVideoHeight = 540;

export const MIN_NUM_LANDMARKS = 5; // Minimum number of landmarks to ensure valid detection



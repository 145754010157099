import useDeviceType from '../../utils/useDeviceType'
import './styles.css'

export const Button = ({text, onClick, buttonType}) => {
    const deviceType = useDeviceType()
    
    return(
        <button onClick={onClick} className={` ${buttonType} ${deviceType !== 'mobile' && 'desktop-width'} button`}>
            {text}
        </button>
    )
}
import {useEffect, useRef} from 'react';
import {Camera} from '@mediapipe/camera_utils';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';
import {Hands, HAND_CONNECTIONS} from '@mediapipe/hands';
import predictValuesApi from "../../api/api";
import {FRAMES_LENGTH_FOR_PREDICT} from "../../../constants";
import {FilesetResolver, HandLandmarker} from '@mediapipe/tasks-vision';


let maxVideoWidth = 960;
let maxVideoHeight = 540;

let attempts = 0
let recordedResults: any[] = [];
let recordedLetters: any[] = [];

function wordsDetectionProcess({
    isDialogOpen,
    showLandmarks,
    setSnackbar,
    isPlayingRef,
    setIsPlaying,
    setShowCountdown,
    setChangeCurrentWord,
    selectedWord,
    setIsLoading,
    setErrorModal
}) {

    useEffect(() => {
        const initialize = async () => {
            setIsLoading(true);
            await createHandLandmarker();
            await initCamera();
        };

        initialize().then(r => setIsLoading(false));

        return () => {
            //@ts-ignore
            const tracks = videoElement.current?.srcObject?.getTracks();
            tracks?.forEach(track => track.stop());
            //@ts-ignore
            camera.current?.close();
        };
    }, []);

    const createHandLandmarker = async () => {
        try {
            const vision = await FilesetResolver.forVisionTasks(
                "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.14/wasm"
            );
            // @ts-ignore
            camera.current = await HandLandmarker.createFromOptions(vision, {
                baseOptions: {
                    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/latest/hand_landmarker.task`,
                    delegate: "GPU",
                },
                runningMode: "VIDEO",
                numHands: 2,
            });
        } catch (error) {
            console.error('Failed to create HandLandmarker:', error);
            setErrorModal(true);
        }
    };

    const initCamera = async () => {
        if (!videoElement.current || !camera.current) return;

        const constraintSets = [
            {
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 360 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 800 },
                    height: { ideal: 600 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 854 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: true
            }
        ];

        for (const constraints of constraintSets) {
            try {
                videoElement.current.srcObject = await navigator.mediaDevices.getUserMedia(constraints);
                videoElement.current.addEventListener("loadeddata", predictWebcam);
                console.log('Camera initialized with constraints:', constraints);

                // @ts-ignore
                maxVideoWidth = constraints.video.width.ideal
                // @ts-ignore
                maxVideoHeight  = constraints.video.height.ideal
                return; // If successful, exit the function
            } catch (error) {
                console.warn('Failed to initialize camera with constraints:', constraints, error);
                // Continue to the next set of constraints
            }
        }

        console.error('Failed to initialize camera with any of the provided constraints.');
        setErrorModal(true);
    };

    async function predictWebcam() {
        const canvas = canvasEl.current;
        if (!canvas || !videoElement.current || !camera.current) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const videoWidth = videoElement.current.videoWidth;
        const videoHeight = videoElement.current.videoHeight;

        canvas.style.width = videoWidth + "px";
        canvas.style.height = videoHeight + "px";
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        const startTimeMs = performance.now();
        //@ts-ignore
        const results = await camera.current.detectForVideo(videoElement.current, startTimeMs);

        // Create a temporary canvas to store the current video frame
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = videoWidth;
        tempCanvas.height = videoHeight;
        const tempCtx = tempCanvas.getContext('2d');
        if (tempCtx) {
            tempCtx.drawImage(videoElement.current, 0, 0, videoWidth, videoHeight);
            //@ts-ignore
            results.image = tempCanvas;
        }

        ctx.save();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (results.landmarks) {
            if (screen.width >= 1280){
                for (const landmarks of results.landmarks) {
                    drawConnectors(ctx, landmarks, HAND_CONNECTIONS, {
                        color: "white",
                        lineWidth: 1
                    });
                    drawLandmarks(ctx, landmarks, { color: "#7FCFF5", lineWidth: 1, radius: 4 });
                }
            }
            // Store the frame as an image in results
            if (!isDialogOpen.current && !isProcessingRef.current && !isPlayingRef.current) {
                if (results.landmarks.length && results.handednesses.length)
                    await processLandmarks(results);
            }
        }

        ctx.restore();

        if (videoElement.current?.srcObject) {
            window.requestAnimationFrame(predictWebcam);
        }
    }


    const videoElement = useRef<HTMLVideoElement | null>(null);
    const hands = useRef<Hands | null>(null);
    const camera = useRef<Camera | null>(null);
    const canvasEl = useRef<HTMLCanvasElement | null>(null);

    const isProcessingRef = useRef(false);

    const getProgress = () => {
        return recordedResults.length;
    };

    const resetProgress = () => {
        recordedResults = [];
        recordedLetters = [];
    };

    const showLandmarksRef = useRef(showLandmarks);

    useEffect(() => {
        showLandmarksRef.current = showLandmarks;
    }, [showLandmarks]);

    async function processLandmarks(results: any) {
        if (isProcessingRef.current) return;
        isProcessingRef.current = true;
        try {
            if (recordedResults.length < FRAMES_LENGTH_FOR_PREDICT) {
                //results = await compressImages(results);
                recordedResults.push(results);

            } else {
                const yPred = await predictValuesApi(recordedResults, selectedWord.current);
                recordedResults = [];
                console.log(yPred)

                if (yPred.word === 'No gesture recognized' || yPred.word === 'Failed') {
                    setSnackbar({
                        open: true,
                        message: "No se ha podido reconocer, intentelo nuevamente",
                        severity: "error"
                    });
                    yPred.word = ""
                }

                if (yPred.word === selectedWord.current || yPred.word.includes(selectedWord.current)) {
                    setSnackbar({
                        open: true,
                        message: `Has completado la palabra!`,
                        severity: "success-word"
                    });
                    setChangeCurrentWord(true)
                    setIsPlaying(true)
                    setShowCountdown(true)
                    attempts = 0
                } else {
                    setSnackbar({
                        open: true,
                        message: "Intentalo de nuevo, no es la palabra correcta. Palabra: " + selectedWord,
                        severity: "error"
                    });
                    setIsPlaying(true)
                    setShowCountdown(true)
                    attempts = attempts + 1
                    if(attempts >= 3){
                        setSnackbar({
                            open: true,
                            message: `Sigue practicando, pasaremos a la siguiente palabra`,
                            severity: "error-word"
                        });
                        recordedLetters = [];
                        setChangeCurrentWord(true)
                        setIsPlaying(true)
                        setShowCountdown(true)
                        attempts = 0
                    }
                }
            }
        } catch (e) {
            console.error('Prediction failed', e);
            recordedResults = [];
            attempts = 0
        }

        isProcessingRef.current = false;
    }

    return {maxVideoHeight, maxVideoWidth, canvasEl, videoElement, getProgress, resetProgress};
}

export default wordsDetectionProcess;

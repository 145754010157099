import React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import './styles.css';

const ErrorModal = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {
    const navigate = useNavigate();

    const handleCloseAndNavigate = () => {
        handleClose();
        navigate('/');
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseAndNavigate}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <div className="modal-error-container">
                <h2 className="modal-error-title" id="modal-title">Permiso de cámara denegado</h2>
                <p className="modal-error-info" id="modal-description">Para acceder al juego, es necesario habilitar el permiso de la cámara en tu dispositivo.</p>
                <div className="btn-error-container">
                    <Button style={{ color: 'white' }} onClick={handleCloseAndNavigate}>Cerrar</Button>
                </div>
            </div>
        </Modal>
    );
};

export default ErrorModal;
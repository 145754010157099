import useDeviceType from "../../utils/useDeviceType";
import { Button } from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import ProfileModal from "../../components/ProfileModal";

import './styles.css'
const EndView = ({redirectTo, text}) => {
    const navigate = useNavigate();
    const deviceType = useDeviceType()

    return(
        <div  className="element-container">
            <ProfileModal/>
            <div>
                <img 
                    src="/dillo-brand.png"
                    width='211px'
                />
            </div>
            <div className="start-game-text">{text}</div>
            <div className={`button-container ${deviceType !== 'mobile' && 'desktop-container'}`}>
                <Button text='Continuar jugando' onClick={() => navigate(redirectTo)} buttonType={'primary'} />
                <Button text='Terminar' onClick={() => navigate('/')} buttonType={'secondary'} />
            </div>
        </div>
    )
}

export default EndView
import useDeviceType from "../../utils/useDeviceType";
import { Button } from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader";

import './styles.css'
import ProfileModal from "../../components/ProfileModal";

const StartView = ({redirectTo, text, loading=false}) => {
    const navigate = useNavigate();
    const deviceType = useDeviceType()

    return(
        <div  className="start-game-element-container" >
            <ProfileModal/>
            <div>
                <img 
                    src="/dillo-brand.png"
                    width='211px'
                />
            </div>
            <div className="start-game-text">{text}</div>
            <div className={`start-game-button-container ${deviceType !== 'mobile' && 'start-game-desktop-container'}`}>
                { loading ?
                    <Loader /> :
                    <Button 
                        text='Jugar'
                        onClick={() => navigate(`${redirectTo}`)}
                        buttonType='primary '
                    /> 
                }
                <Button text='Volver' onClick={() => navigate('/')} buttonType={'secondary'} />
            </div>
        </div>
    )
}

export default StartView
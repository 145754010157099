import {Outlet, Navigate} from 'react-router-dom';

const PrivateRoute = () => {
    const auth = localStorage.getItem('pocketbase_auth');

    return(
        auth ? <Outlet/> : <Navigate to="/ingresar"/>
    )
}

export default PrivateRoute
import React, { useEffect, useState, memo } from 'react'
import ColoredWords from '../ColoredWords'
import normalizeWord from '../../utils/normalizeWords'
import useDeviceType from '../../utils/useDeviceType'

import "./styles.css"

/**
 * Component that show words to be represented.
 * @param {Array<string>} wordsList - List of word to play.
 * @param {string} currentWord - current word to highlight in header.
 * @param {number} currentLetterIndex - number that represent the position of current letter to represent in word, to highlight it.
 */
type WordsListProps = {
    wordsList: string[],
    currentWord: string,
    currentLetterIndex: number
}

const WordsList: React.FC<WordsListProps> = ({ wordsList, currentWord, currentLetterIndex }) => {
    const deviceType = useDeviceType()

    const renderWords = () => {
        const indexWord = wordsList.indexOf(currentWord)
        const renderedWords = [];

        for(let i = 2; i>= -2; i--){
            const newIndex = (indexWord + i + wordsList.length) % wordsList.length;
            renderedWords.push(wordsList[newIndex])
        }
        
        return renderedWords.map((word, index) => (
            <span key={index} className='text-desktop'>
                { word && currentWord === normalizeWord(word) ?
                    <ColoredWords word={word?.toUpperCase()} currentLetterIndex={currentLetterIndex} /> : word?.toUpperCase()
                } 
            </span>
        ))
    }

    return (
        <div className='words-container'>
            {deviceType === 'mobile' ? 
                (<span> 
                    <ColoredWords 
                        word={currentWord?.toUpperCase()}
                        currentLetterIndex={currentLetterIndex}
                    />
                </span > ) :
                renderWords()
            }
        </div>
    )
}

export default memo(WordsList);
import "./styles.css"

type CurrentWordProps = {
    word: string,
    currentLetterIndex?: number
}


const ColoredWords:React.FC<CurrentWordProps> = ({word, currentLetterIndex}) => {
    if(currentLetterIndex === undefined) {
        return <span className='letters current-word'>{ word } </span>
    }

    return (
      <div>
        { word?.split('').map( (letter, idx) => (
            <span 
                key={`letter${idx}`} 
                className={`letters ${currentLetterIndex === idx && 'current-letter'}`}
            >
                { letter }
            </span>
        ))}
      </div>
    )
}

export default ColoredWords;
import React, { memo } from 'react'
import ColoredWords from '../ColoredWords'

import "./styles.css"

/**
 * Component that show words to be represented.
 * @param {Array<string>} wordsList - List of word to play.
 * @param {string} currentWord - current word to highlight in header.
 * @param {number} currentLetterIndex - number that represent the position of current letter to represent in word, to highlight it.
 */
type WordsListProps = {
    wordsList: string[],
    currentWord: string,
}

const WordsList: React.FC<WordsListProps> = ({ currentWord }) => {

    return (
        <div className='words-container'>
            <span> 
                    <ColoredWords word={currentWord?.toUpperCase()}/>
            </span > 
        </div>
    )
}

export default memo(WordsList);
import { getAvailableWords, getWords } from "../api/api";

export const fetchWords = async(setIsLoadingApi) => {
    setIsLoadingApi(true)

    // await new Promise((resolve) => setTimeout(resolve, 3000));
    const [fetchedWords] = await Promise.all([getAvailableWords()
        .then(words => getWordsArray(words))
        .finally(() => {
            setIsLoadingApi(false)
        })]);
        
        return fetchedWords
}

export const fetchWordsComplete = async(setIsLoadingApi) => {
    setIsLoadingApi(true)

    // await new Promise((resolve) => setTimeout(resolve, 3000));
    const [fetchedWords] = await Promise.all([getWords()
        .then(words => getWordsArray(words))
        .finally(() => {
            setIsLoadingApi(false)
        })]);
    return fetchedWords

}

const getWordsArray = (words: unknown): string[] => {
    if (!Array.isArray(words)) {
        throw new Error("Input must be an array");
    }

    const totalOfWords: string[] = words.map(word => {
        if (typeof word !== 'string') {
            throw new Error("Array elements must be strings");
        }
        return word;
    });

    const wordsArray: string[] = [];
    for (let i = 0; i < 5; i++) {
        const arrayIndex = Math.round(Math.random() * (totalOfWords.length - 1));
        const word = totalOfWords[arrayIndex];

        // Check if the word contains '/' and split if it does
        if (word.includes('/')) {
            const parts = word.split('/');
            parts.forEach(part => {
                if (!wordsArray.includes(part)) {
                    wordsArray.push(part);
                }
            });
        } else {
            if (!wordsArray.includes(word)) {
                wordsArray.push(word);
            } else {
                i--;
            }
        }
    }

    console.log(wordsArray)
    return wordsArray;
};
